import * as React from "react";
import { Container, Row} from "react-bootstrap";
import Layout from "../components/layout";
import Seo from "../components/seo";
import "../components/css/NewsDetail.scss";
import { Link } from "gatsby";
import { AiFillLinkedin } from 'react-icons/ai';
import { FaTwitter } from 'react-icons/fa';
import { FaYoutube } from 'react-icons/fa';
import { FiArrowLeft } from 'react-icons/fi';


const EdgeqPressKit8 = () => (
  <Layout>
    <Seo title="EdgeQ | Actiontec and EdgeQ Announces" />


    <section className="News-detail">
        <Container>

                <Row>

                  <div className="detail-head">
                   <Link to="/media/#Newss" className="back-btn"><FiArrowLeft/>News Announcements</Link>

                  <h2>Actiontec and EdgeQ Announces Flexible, and Scalable Small Cell Architecture Platform for 4G and 5G SA/NSA</h2>
                  </div>

                  <div className="detail-para">
                    
              {/* <p className="text-left"><i>EdgeQ Sets Industry Precedence with an Open, Scalable 32TRX and 64TRX maMIMO In-Line Accelerator for Base Station Platforms </i></p> */}

              <div className="ajit-social">
                <ul className="social-icons">
                  <li>  
                    <a aria-label="Save" href="https://www.linkedin.com/authwall?trk=bf&trkInfo=AQFQA4el-DoX9AAAAX7t1dUotlbczx1ZDTlCKPTejfiFEWL5GGZd5oqr6zWvtFKLF3CnnFGCCLco9AXaX4nEgd1nxfFvszEVY7-NA8YXEk9NExe7VTUUStGaSa7qf9cyJahXczA=&originalReferer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Fedgeq" target="_blank" rel="noopener noreferrer"><AiFillLinkedin/></a>
                  </li>
                  <li>  
                    <a aria-label="Save" href="https://twitter.com/edgeq_inc?lang=en" target="_blank" rel="noopener noreferrer"><FaTwitter/></a>
                  </li>
                  <li>  
                    <a aria-label="Save" href="https://www.youtube.com/channel/UChEG47AbYVKYw46EGhE_dcg" target="_blank" rel="noopener noreferrer"><FaYoutube/></a>
                  </li>
                </ul>
              </div>
            <p>Actiontec, a US-based company providing solutions for major operators for the last three decades in wired and wireless access solutions, is announcing its Software Programmable & Modular hardware Architecture for 4G & 5G SA/NSA small cells supporting various O-RAN compliant Split options including All-in-One Split 0, Split 2, and Split 6 which are configurable as the network evolves based on users’ demands.</p>

            <p>This innovative architecture enables operators to adapt multiple technologies and various market spectrum requirements to provide flexible and scalable small cell solutions for the best use of the owned or shared spectrum including 4G, 5G SA/NSA, and WiFi.  This architecture is based on EdgeQ’s software-defined, multi-mode 4G/5G Base Station-on-a-Chip. </p>
 
            <p>Actiontec offers high performance versus price and easy deployment with the Plug-and-Play installations and an integrated AI Cloud-based commercial grade market-proven management system called OPTIM to optimize the performance and minimize the OPEX for operators.</p>
            
            <p>“Actiontec is offering innovative, agile, flexible, and scalable small cell technologies on our modular hardware platform architecture to enable configurable plug-and-play non-planned small cell deployments as the network evolves dynamically to adapt to customer demands especially to service hard-to-reach indoor coverage use cases.  These small cell technologies will enable you to have 5 bars of indoor coverage at the best price/performance” said Brian Henrichs, CEO of Actiontec.</p>

            <p>“EdgeQ was founded on the belief of simplifying wireless infrastructure in a constructive, frictionless manner.  Our collaboration with Actiontec fuses silicon ingenuity with modular design to help the market navigate through all the multiplicities and complexities of 4G and 5G in an elegant manner.  By collapsing multiple spectrums, multiple bands, and multiple protocols into a compact footprint that is cloud managed, we can converge connectivity into a simple construct, ideal for small cells," said Vinay Ravuri, CEO and Founder of EdgeQ.</p>

            <p>Initial samples will be ready for early access customers in 2Q2023 and mass production targeted in 1Q2024.</p>            

            <p>The Actiontec small cell is at Hall 6 – 6B7EX and EdgeQ’s Base Station-on-a-Chip can be seen at Hall 2 - 2A4MR.</p>


            <b>About Actiontec Electronics</b>
            <p>Take the in-home Internet, Wi-Fi, and 4G & 5G small cell experience to the next level with Actiontec Electronics. Our award-winning products — including 10 Gigabit Ethernet Routers, 10G PON ONT/ONUs, high-speed VDSL gateways, and home networking solutions — are deployed by many of the largest telecom carriers in North America. For about 3 decades, service providers have relied on our innovation and commitment to quality. With more than 68 million connected home devices shipped to date, we are one of the largest Wired & Wireless Access Point suppliers in North America. Founded in 1993, Actiontec is headquartered in Santa Clara, CA. To learn more about Actiontec, visit <Link target="_blank" to="https://www.actiontec.com/">www.actiontec.com</Link></p>
            <br />
            <br />
            <b>About EdgeQ</b>
            <br />
            <br />
            <p>EdgeQ is a leading innovator in 5G systems-on-a-chip. The company is headquartered in Santa Clara, CA, with offices in San Diego, CA and Bangalore, India. Led by executives from Qualcomm, Intel, and Broadcom, EdgeQ is pioneering converged connectivity and AI that is fully software-customizable and programmable. The company is backed by world-renowned investors. To learn more about EdgeQ, visit <Link to="/">www.edgeq.io</Link></p>
            {/* <b>Contacts</b>
            <br />
            <br />
            <a target="_blank" href="https://goo.gl/maps/qwqsKwcTcEVokx1e6">Jordan Tewell, 10Fold Communications</a>
            <br />
            <a href="mailto:edgeq@10fold.com">edgeq@10fold.com</a>
            <br />
            <a href="tel:+4156666066">(415) 666-6066</a> */}
        </div>
                </Row>
        </Container>
    </section>


  </Layout>
);

export default EdgeqPressKit8;